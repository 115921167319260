import React, { useState } from "react";
import { signIn } from "../utils/Auth";
import "./Signin.css";
import BackButton from "../utils/BackButton";
import { Link, useNavigate } from "react-router-dom";

const SignInComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const user = await signIn(email, password);
      navigate("/home");
    } catch (error) {
      alert("Invalid login details");
    }
  };
  const handleSignUp = () => {
    // Navigate to the SignUp component
    navigate("/Sign-up");
  };

  return (
    <div className="div">
      <div className="sign-in-form">
        <BackButton />
        <h2 className="h2">Sign In</h2>
        <input
          className="input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span>
          <button className="button1" onClick={handleSignIn}>
            Sign In
          </button>
        </span>

        <Link to={`/Forget-password`}>
          <span className="signup-link">forgot Password</span>
        </Link>
      </div>
    </div>
  );
};

export default SignInComponent;
