import React, { useState } from "react";
import {
  FiArrowLeft,
  FiArrowLeftCircle,
  FiHeart,
  FiMessageCircle,
  FiShare,
} from "react-icons/fi";

import "../auth/Signin.css";

const BackButton = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <div>
      <span className="back-button" onClick={handleBack}>
        <FiArrowLeftCircle />
      </span>
    </div>
  );
};

export default BackButton;
