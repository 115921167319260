// Welcome.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";
import logo from "../../img/logo2.png";

const Welcome = () => {
  const navigate = useNavigate();

  const handleSignUp = () => {
    // Navigate to the SignUp component
    navigate("/Sign-up");
  };

  const handleSkip = () => {
    // Navigate to the home component
    navigate("/home");
  };

  const handleLogIn = () => {
    // Navigate to the LogIn component
    navigate("/sign-in");
  };

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <img className="Header-img" src={logo} />
        <h2 className="welcome-title">Welcome to Chidels Food and Pastries</h2>
        <p className="welcome-message">
          We provide you with the best of taste and value services for your
          money. We are not just an eatery, we are your personal chef.
        </p>
        <div className="welcome-buttons">
          <div>
            <button className="button" onClick={handleLogIn}>
              Log in
            </button>
          </div>
          <p> or</p>
        </div>
        <div>
          <p className="signup-link">
            Don't have an account? <span onClick={handleSignUp}>Sign up</span> .
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
