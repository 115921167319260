// LandingPage.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import BannerSlider from "./utils/BannerSlider";

import Rice from "../img/Rice.jpg";
import SoupandSwallows from "../img/soup2.png";
import Cakes from "../img/cake.png";
import Beans from "../img/Beans.jpg";
import Porridge from "../img/porrid.jpg";
import Snacks from "../img/snacks.jpg";
import SoupBowls from "../img/soupbowls.png";
import Drinks from "../img/drink.jpg";
import FeaturedProductsList from "./FeaturedProductList";
import {
  FaCheck,
  FaExchangeAlt,
  FaPhoneSquareAlt,
  FaPhoneVolume,
  FaShippingFast,
} from "react-icons/fa";

const categories = [
  "Rice",
  "Soup",
  "Soup Bowls",
  "Beans",
  "Porridge",
  "Snacks",
  "Cakes",
  "Drinks",
];

const categoryImages = {
  Rice: Rice,
  Soup: SoupandSwallows,
  "Soup Bowls": SoupBowls,
  Beans: Beans,
  Porridge: Porridge,
  Snacks: Snacks,
  Cakes: Cakes,
  Drinks: Drinks,
};

const CategoryPage = () => {
  return (
    <div className=" landing-page" style={{ borderBottom: "2px solid red" }}>
      <div className="categories-container">
        <div className="categories-page-grid">
          {categories.map((category) => (
            <Link
              to={`/products/${category}`}
              className="category-page-card"
              key={category}
              style={{
                backgroundImage: `url(${categoryImages[category]})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "#0001017e",
              }}
            >
              <span className="category-page-title">{category}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
