// Firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  addDoc,
  updateDoc,
  doc,
  collection,
  onSnapshot,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  orderBy,
  limit,
  deleteDoc,
} from "firebase/firestore";
import { getDatabase, ref, set } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD4WQEj5g_jXoiCciRvhPIcjrh0lSIenD8",
  authDomain: "chidelsfoodpastries.firebaseapp.com",
  projectId: "chidelsfoodpastries",
  storageBucket: "chidelsfoodpastries.appspot.com",
  messagingSenderId: "653381188971",
  appId: "1:653381188971:web:bd8c6ab7ff99aab81b202e",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const database = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp); // Update this line

const storage = getStorage(firebaseApp);

const authStateChanged = (callback) => {
  return auth.onAuthStateChanged(callback);
};

const initializeDatabase = () => {
  const postsRef = ref(database, "posts");
  set(postsRef, {
    postId1: {
      title: "Sample Title 1",
      description: "Sample Description 1",
      image: "sample-image-1.jpg",
      link: "https://example.com/1",
    },
    postId2: {
      title: "Sample Title 2",
      description: "Sample Description 2",
      image: "sample-image-2.jpg",
      link: "https://example.com/2",
    },
    // Add more posts as needed
  });
};

export {
  auth,
  database,
  storage,
  firestore,
  authStateChanged,
  firebaseApp as default,
  initializeDatabase,
  orderBy,
  collection,
  getDocs,
  setDoc,
  limit,
  addDoc,
  onSnapshot,
  deleteDoc,
  uploadBytes,
  storageRef,
  ref,
  getDownloadURL,
  doc,
  updateDoc,
  getDoc,
  uploadBytesResumable,
  where,
  query,
};
