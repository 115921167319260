// Order.js
import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  firestore,
  auth,
} from "../components/utils/Firebase";

import "./order.css";

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async (user) => {
      try {
        if (user) {
          const ordersRef = collection(firestore, "orders");
          const userOrdersQuery = query(
            ordersRef,
            where("userId", "==", user.uid)
          );
          const ordersSnapshot = await getDocs(userOrdersQuery);
          const ordersList = ordersSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setOrders(ordersList);
        }
      } catch (error) {
        console.error("Error fetching orders:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchOrders(user);
      } else {
        // Handle the case when the user is not logged in
        setLoading(false);
      }
    });

    return () => unsubscribe(); // Cleanup the subscription when the component unmounts
  }, []);

  if (loading) {
    return <p>Loading orders...</p>;
  }

  return (
    <div className="order-container">
      <h2>Your Orders</h2>
      {orders.length > 0 ? (
        <div>
          {orders.map((order) => (
            <div key={order.id} className="order-item">
              <h3>Order ID: {order.id}</h3>
              <p>Total Amount: N{order.totalAmount}</p>
              <p>Payment Method: {order.paymentMethod}</p>
              <p
                style={{
                  background: "green",
                  width: "120px",
                  padding: "5px",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                {order.orderStatus}
              </p>
              <div className="order-grid">
                {order.items.map((item) => (
                  <div className="order-card" key={item.id}>
                    <img className="order-image" src={item.productImage} />
                    {""}
                    <p>
                      {item.productName} {""}N{item.productPrice}
                    </p>
                    {""}
                    {""}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>You have no orders yet.</p>
      )}
    </div>
  );
};

export default Order;
