// ProductHomePage.jsx
import React, { useState, useEffect } from "react";
import {
  firestore,
  collection,
  getDocs,
  auth,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  getDoc,
} from "../components/utils/Firebase";
import { FiArrowLeft, FiArrowRight, FiLoader } from "react-icons/fi";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./product.css";

import { FaCartPlus, FaHeart } from "react-icons/fa";
import RatingComponent from "./utils/Rating";
import AddToFeaturedButton from "./utils/FeaturedButton";
import CategoryPage from "./Category";

// ... (previous imports)

// ... (previous imports)

const ProductHomePage = (isAdmin) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(30); // Set the number of products per page
  const { category } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(firestore, "posts");
        const snapshot = await getDocs(productsCollection);

        const productsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    const filterProductsByCategory = () => {
      setSelectedCategory(category);
    };

    filterProductsByCategory();
    fetchProducts();
  }, [category]);

  const handleLikeClick = async (product) => {
    const likesRef = collection(firestore, "likes");

    // Check if the product is already in the cart
    const querySnapshot = await getDocs(likesRef);
    const existingLikesItem = querySnapshot.docs.find(
      (doc) => doc.data().productId === product.id
    );

    if (existingLikesItem) {
      // If the product is already in the cart, update the quantity
      const likesItemRef = doc(likesRef, existingLikesItem.id);
      await updateDoc(likesItemRef, {
        likes: existingLikesItem.data().likes + 1,
      });
    } else {
      await addDoc(likesRef, {
        productId: product.id,
        productName: product.title,
        productPrice: product.price,
        productImage: product.postImage,
        likes: 1,
        // Add other product details as needed
      });
    }
  };

  const handleAddToCart = async (product) => {
    setLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        const cartRef = collection(firestore, "users", user.uid, "cart");

        // Check if the product is already in the cart
        const querySnapshot = await getDocs(cartRef);
        const existingCartItem = querySnapshot.docs.find(
          (doc) => doc.data().productId === product.id
        );

        if (existingCartItem) {
          // If the product is already in the cart, update the quantity
          const cartItemRef = doc(cartRef, existingCartItem.id);
          await updateDoc(cartItemRef, {
            quantity: existingCartItem.data().quantity + 1,
          });
          alert("Quantity updated in cart successfully!");
        } else {
          await addDoc(cartRef, {
            productId: product.id,
            productName: product.title,
            productPrice: product.price,
            productImage: product.postImage,
            quantity: 1,
            // Add other product details as needed
          });

          alert("Item added to cart successfully!");
        }
      } else {
        alert("User not logged in. Call to order item.");
        navigate(`/login`);
        // You can implement your navigation logic here
        // Example: navigate('/login');
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = selectedCategory
    ? products
        .filter((product) => product.mainCategory === selectedCategory)
        .slice(indexOfFirstProduct, indexOfLastProduct)
    : [];

  const totalPages = Math.ceil(
    (selectedCategory
      ? products.filter((product) => product.mainCategory === selectedCategory)
          .length
      : products.length) / productsPerPage
  );

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div className="main-container">
      <CategoryPage />
      <h2>{selectedCategory ? `${selectedCategory} Products` : null}</h2>

      <div id="products-grid">
        {currentProducts.length > 0 ? (
          currentProducts.map((product) => (
            <div className="product-card" key={product.id}>
              <AddToFeaturedButton product={product} isAdmin={isAdmin} />
              <Link
                className="links"
                to={`/products/${selectedCategory}/${product.id}`}
              >
                <img className="img" src={product.image} alt="Product" />

                <span onClick={() => handleLikeClick(product)} className="like">
                  <FaHeart />
                </span>
                <p> {product.title}</p>
                {product.prices && product.prices.length > 0 ? (
                  <div>
                    <p>Price: N{product.prices[0].price}</p>
                    <p>Delivery in: {product.duration} minutes</p>
                    <p>
                      <span>Ratings:</span>
                      <span>
                        <RatingComponent productId={product.id} />
                      </span>
                    </p>
                  </div>
                ) : (
                  <p>No price information available</p>
                )}
                <button>View Order</button>
              </Link>
            </div>
          ))
        ) : (
          <div>
            <h2>No item listed for sale</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductHomePage;
