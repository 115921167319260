import React, { useState } from "react";
import { forgotPassword } from "../utils/Auth";
import BackButton from "../utils/BackButton";
import "./Signin.css";

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleForgotPassword = async () => {
    try {
      await forgotPassword(email);
      setMessage("Password reset email sent!");
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="div">
      <div className="sign-in-form">
        <BackButton />
        <h2 className="h2">Forgot Password</h2>
        <input
          className="input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button className="button1" onClick={handleForgotPassword}>
          Reset Password
        </button>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
