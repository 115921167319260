import React, { useState } from "react";
import "./BookingForm.css"; // Import the CSS file

const CateringBookingForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [guestCount, setGuestCount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const whatsappMessage = `Booking Request:\nName: ${name}\nEmail: ${email}\nEvent Date: ${eventDate}\nGuest Count: ${guestCount}`;
    window.location.href = `https://wa.me/2348130979300/?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    setName("");
    setEmail("");
    setEventDate("");
    setGuestCount("");
  };

  return (
    <div className="catering-booking-form">
      <p className="intro-text">
        Welcome to our catering service! Please fill out the form below to book
        our services for your event.
      </p>

      <form onSubmit={handleSubmit} className="booking-form">
        <label>
          Name:
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="enter your name"
            required
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="enter your email"
          />
        </label>

        <label>
          Event Date:
          <input
            type="date"
            value={eventDate}
            onChange={(e) => setEventDate(e.target.value)}
            required
          />
        </label>

        <label>
          Guest Count:
          <input
            type="number"
            value={guestCount}
            onChange={(e) => setGuestCount(e.target.value)}
            required
            placeholder="expected number of guess"
          />
        </label>

        <button type="submit">Book Catering Service</button>
      </form>
    </div>
  );
};

export default CateringBookingForm;
