import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import firebaseApp, { database, storage } from "./Firebase"; // Import the Firebase app instance

const auth = getAuth(firebaseApp);

// Sign Up
export const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

// Sign In
export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    throw error;
  }
};

export const updateProfile = async (user, name, bio, image) => {
  try {
    // Update display name
    await user.updateProfile({
      displayName: name,
    });

    // Update bio in the database
    if (bio) {
      const userDatabaseRef = database.ref("users").child(user.uid);
      await userDatabaseRef.update({
        bio: bio,
      });
    }
    // Upload profile image to Firebase Storage
    if (image) {
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`profile-images/${user.uid}`);
      await imageRef.put(image);
      const imageURL = await imageRef.getDownloadURL();

      // Update profile image URL in the database or user object
      // ...
    }
  } catch (error) {
    throw error;
  }
};
