import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { signUp } from "../utils/Auth";
import "./Signin.css";
import BackButton from "../utils/BackButton";

const SignUpComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignUp = async () => {
    try {
      const user = await signUp(email, password);

      alert("Signed up:", user);
      navigate("/");
    } catch (error) {
      console.error("Invalid registeration details, please try again");
    }
  };

  return (
    <div className="div">
      <div className="sign-in-form">
        <BackButton />
        <h2 className="h2">Sign Up</h2>
        <input
          className="input"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="input"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="button1" onClick={handleSignUp}>
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default SignUpComponent;
