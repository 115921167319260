import React, { useState } from "react";
import { firestore, auth } from "./utils/Firebase";
import { addDoc, collection } from "firebase/firestore";
import "./CreatePost.css"; // Import your custom styles
import PostImage from "./utils/PostImage";
import { useNavigate } from "react-router-dom";

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(""); // Array to store multiple prices
  const [swallowOptions, setSwallowOptions] = useState([
    { option: "", price: "" },
  ]); // Array to store multiple swallow options
  const [bowlsOptions, setBowlsOptions] = useState([{ option: "", price: "" }]);
  const [duration, setDuration] = useState("");
  const [postImage, setPostImage] = useState("");
  const [prices, setPrices] = useState([{ option: "", price: "" }]);
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [mainCategory, setMainCategory] = useState("");

  const navigate = useNavigate();
  const mainCategories = [
    "Rice",
    "Soup",
    "Beans",
    "Soup Bowls",
    "Porridge",
    "Drinks",
    "Cakes",
    "Snacks",
  ];

  const subCategories = {
    Rice: [
      "Jollof Rice",
      "Fried Rice",
      "Rice & Stew",
      "Coconut Rice",
      "Banga Rice",
      "Offada Rice",
      "Chinese Rice & sauce",
      "Chinese Fried Rice",
    ],
    Soup: [
      "Vegetable soup",
      "Egusi soup",
      "Aphan Soup",
      "Eforiro soup",
      "Ogbonor soup",
      "White soup",
      "Okro soup",
      "Banga Soup",
      "Ewedu",
      "Bitterleaf soup",
    ],
    "Soup Bowls": [
      "Vegetable soup",
      "Egusi soup",
      "Aphan Soup",
      "Eforiro soup",
      "Ogbonor soup",
      "White soup",
      "Okro soup",
      "Banga Soup",
      "Ewedu",
      "Bitterleaf soup",
    ],
    Snacks: ["Shawama", "Chin chin", "Meat pie", "Small Chops", "Corndog"],
    Cakes: ["vanilla", "Chocolate", "Red velvet", "Lemon", "Forest", "Banana"],
    Drinks: ["Chapman", "Tigernut", "Cocktails", "Mocktails"],
    "Beans ": ["Beans", "Akara", "Moi moi"],
    Porridge: ["Plantain", "Yam"],
  };

  const handleSubmit = async () => {
    if (
      !title ||
      !description ||
      !prices ||
      !duration ||
      !postImage ||
      !mainCategory
    ) {
      alert(
        "Title, description, Price, duration, postImage, collection, mainCategory are required fields."
      );
      return;
    }

    const postData = {
      title,
      description,
      prices,
      duration,
      mainCategory,
      subCategory,
      image: postImage,
    };

    if (mainCategory === "Soup") {
      postData.swallowOptions = swallowOptions;
    } else if (mainCategory === "Soup Bowls") {
      postData.bowlsOptions = bowlsOptions;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated.");
        return;
      }

      // Add post data to Firestore
      await addDoc(collection(firestore, "posts"), postData);

      // Clear form after submission
      setTitle("");
      setDescription("");
      setPostImage("");
      setPrices("");
      setDuration("");
      setSubCategory("");
      setMainCategory("");
      alert("Menu created successfully");
      navigate("/home");
    } catch (error) {
      console.error("Error submitting post:", error.message);
    }
  };

  const handleImageUpload = (imageUrl) => {
    setPostImage(imageUrl);
  };

  return (
    <div className="main-container create-post-container">
      <h2>Add a Food Menu</h2>
      <label>Collection:</label>
      <select
        value={mainCategory}
        onChange={(e) => setMainCategory(e.target.value)}
        required
        className="input-field"
      >
        <option value="">Select a collection</option>
        {mainCategories.map((col) => (
          <option key={col} value={col}>
            {col}
          </option>
        ))}
      </select>

      {mainCategory && (
        <div>
          <label>Main Category:</label>
          <select
            value={subCategory}
            onChange={(e) => setSubCategory(e.target.value)}
            required
            className="input-field"
          >
            <option value="">Select a main category</option>
            {subCategories[mainCategory].map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="img-container">
        <PostImage
          className="img-container"
          onImageUpload={handleImageUpload}
          required
        />
      </div>
      <label>Title:</label>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
        className="input-field"
      />
      <label>Delivery Time:</label>
      <input
        type="number"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        required
        className="input-field"
      />

      <label>Description:</label>
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
        className="input-field"
      />
      <label>Prices:</label>

      {prices.map((price, index) => (
        <div key={index}>
          <input
            type="text"
            value={price.option}
            onChange={(e) => {
              const updatedPrices = [...prices];
              updatedPrices[index].option = e.target.value;
              setPrices(updatedPrices);
            }}
            placeholder="Option"
            className="input-field"
          />
          <input
            type="number"
            value={price.price}
            onChange={(e) => {
              const updatedPrices = [...prices];
              updatedPrices[index].price = e.target.value;
              setPrices(updatedPrices);
            }}
            placeholder="Price"
            required
            className="input-field"
          />
          {index > 0 && (
            <button
              onClick={() => {
                const updatedPrices = [...prices];
                updatedPrices.splice(index, 1);
                setPrices(updatedPrices);
              }}
            >
              Remove
            </button>
          )}
        </div>
      ))}
      <button
        onClick={() => {
          setPrices([...prices, { option: "", price: "" }]);
        }}
      >
        Add Price Option
      </button>

      {mainCategory === "Soup" && (
        <div>
          <label>Swallow Options:</label>
          {swallowOptions.map((swallow, index) => (
            <div key={index}>
              <input
                type="text"
                value={swallow.option}
                onChange={(e) => {
                  const updatedSwallows = [...swallowOptions];
                  updatedSwallows[index].option = e.target.value;
                  setSwallowOptions(updatedSwallows);
                }}
                placeholder="Option"
                className="input-field"
              />
              <input
                type="number"
                value={swallow.price}
                onChange={(e) => {
                  const updatedSwallows = [...swallowOptions];
                  updatedSwallows[index].price = e.target.value;
                  setSwallowOptions(updatedSwallows);
                }}
                placeholder="Price"
                required
                className="input-field"
              />
              {index > 0 && (
                <button
                  onClick={() => {
                    const updatedSwallows = [...swallowOptions];
                    updatedSwallows.splice(index, 1);
                    setSwallowOptions(updatedSwallows);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            onClick={() => {
              setSwallowOptions([...swallowOptions, { option: "", price: "" }]);
            }}
          >
            Add swallow Option
          </button>
        </div>
      )}
      {mainCategory === "Soup Bowls" && (
        <div>
          <label>Bowls Options:</label>
          {bowlsOptions.map((bowls, index) => (
            <div key={index}>
              <input
                type="text"
                value={bowls.option}
                onChange={(e) => {
                  const updatedBowls = [...bowlsOptions];
                  updatedBowls[index].option = e.target.value;
                  setBowlsOptions(updatedBowls);
                }}
                placeholder="Option"
                className="input-field"
              />
              <input
                type="number"
                value={bowls.price}
                onChange={(e) => {
                  const updatedBowls = [...bowlsOptions];
                  updatedBowls[index].price = e.target.value;
                  setBowlsOptions(updatedBowls);
                }}
                placeholder="Price"
                required
                className="input-field"
              />
              {index > 0 && (
                <button
                  onClick={() => {
                    const updatedBowls = [...bowlsOptions];
                    updatedBowls.splice(index, 1);
                    setBowlsOptions(updatedBowls);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            onClick={() => {
              setBowlsOptions([...bowlsOptions, { option: "", price: "" }]);
            }}
          >
            Add Price Option
          </button>
        </div>
      )}

      {mainCategory === "Soup Bowls" && (
        <div>
          <label>Quantity Options:</label>
          {swallowOptions.map((swallow, index) => (
            <div key={index}>
              <input
                type="text"
                value={swallow.option}
                onChange={(e) => {
                  const updatedSwallows = [...swallowOptions];
                  updatedSwallows[index].option = e.target.value;
                  setSwallowOptions(updatedSwallows);
                }}
                placeholder="Option"
                className="input-field"
              />
              <input
                type="number"
                value={swallow.price}
                onChange={(e) => {
                  const updatedSwallows = [...swallowOptions];
                  updatedSwallows[index].price = e.target.value;
                  setSwallowOptions(updatedSwallows);
                }}
                placeholder="Price"
                required
                className="input-field"
              />
              {index > 0 && (
                <button
                  onClick={() => {
                    const updatedSwallows = [...swallowOptions];
                    updatedSwallows.splice(index, 1);
                    setSwallowOptions(updatedSwallows);
                  }}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            onClick={() => {
              setSwallowOptions([...swallowOptions, { option: "", price: "" }]);
            }}
          >
            Add Quantity
          </button>
        </div>
      )}

      <button onClick={handleSubmit} className="submit-button">
        Post
      </button>
    </div>
  );
};

export default CreatePost;
