import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "./utils/Firebase";
import { collection, addDoc, updateDoc, doc, setDoc } from "firebase/firestore";
import "./UpdateProfile.css";

const UpdateProfile = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [bio, setBio] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation
    if (!username || !bio || !specialty) {
      setError("All fields are required");
      return;
    }

    // Update user profile
    try {
      const user = auth.currentUser;
      alert("Updating profile for user:", user.uid);

      const userRef = doc(firestore, "users", user.uid);

      await setDoc(
        userRef,
        {
          username,
          bio,
          specialty,
        },
        { merge: true }
      );

      console.log("Profile updated successfully");
      navigate("/user-profile");
    } catch (error) {
      setError("Error updating profile. Please try again.");
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="update-profile-container">
      <h2>Update Profile</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <label htmlFor="bio">Bio:</label>
        <textarea
          className="update-textarea"
          id="bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />

        <label htmlFor="specialty">Specialty:</label>
        <select
          className="option-select"
          id="specialty"
          value={specialty}
          onChange={(e) => setSpecialty(e.target.value)}
        >
          <option value="" disabled>
            Select Specialty
          </option>
          <option value="software-engineer">Software Engineer</option>
          <option value="graphic-designer">Graphic Designer</option>
          <option value="data-scientist">Data Scientist</option>
        </select>

        <button type="submit">Update Profile</button>
      </form>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UpdateProfile;
