import React from "react";
import {
  FaHome,
  FaSearch,
  FaPlusSquare,
  FaHeart,
  FaUser,
  FaCashRegister,
  FaBlog,
  FaNewspaper,
  FaCartPlus,
  FaCartArrowDown,
  FaAddressBook,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./BottomNavBar.css";
import CartIcon from "../Cart/CartIcon";

const BottomNavBar = ({ isAuthenticated, isAdmin = false }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="bottom-navbar">
      <span onClick={() => handleClick("/home")} className="nav-item">
        <FaHome />
        <span>Menu</span>
      </span>
      <span onClick={() => handleClick("/discover")} className="nav-item">
        <FaSearch />
        <span>Discover</span>
      </span>

      <span onClick={() => handleClick("/booking")} className="nav-item">
        <FaAddressBook />
        <span>Book</span>
      </span>

      {isAdmin && (
        <span onClick={() => handleClick("/sell")} className="nav-item">
          <FaCartPlus />
          <span>Sell</span>
        </span>
      )}
      {isAdmin && (
        <span onClick={() => handleClick("/order")} className="nav-item">
          <FaCashRegister />
          <span>Orders</span>
        </span>
      )}
    </div>
  );
};

export default BottomNavBar;
