import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ServiceComponent.css"; // Create a corresponding CSS file for styling

import wedding from "../img/wedding.jpg";
import birthday from "../img/birthday.jpg";
import coporate from "../img/coporate.jpg";

const ServiceContainer = () => {
  const services = [
    {
      title: "Wedding Catering",
      description:
        "Exquisite catering for your special day. Let your Welding refreshment make the news, get everyone talking about it",
      image: wedding,
    },
    {
      title: "Corporate Events",
      description:
        "Professional catering for corporate events. Busy for a bite, lets show you what real taste feels like",
      image: coporate,
    },
    {
      title: "Private Parties",
      description:
        "Tailored catering for your private gatherings, get togethers, birthday parties, etc, we provide the best of taste just for you.",
      image: birthday,
    },
    // Add more services as needed
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="service-container">
      <h2>Our Catering Services</h2>
      <Slider {...sliderSettings}>
        {services.map((service, index) => (
          <div key={index} className="service-slide">
            <img src={service.image} alt={service.title} />
            <div className="service-details">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ServiceContainer;
