// FeaturedProducts.js
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  firestore,
  updateDoc,
  doc,
  addDoc,
  auth,
} from "./utils/Firebase";
import "./product.css";
import RatingComponent from "./utils/Rating";
import { FaCartPlus } from "react-icons/fa";
import AddToFeaturedButton from "./utils/FeaturedButton";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import LoaderComponent from "./utils/Loader";
import { Link } from "react-router-dom";

const FeaturedProducts = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(30);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const fetchFeaturedProducts = async (isAdmin) => {
      try {
        const featuredCollectionRef = collection(firestore, "featuredProducts");
        const snapshot = await getDocs(featuredCollectionRef);

        const products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setFeaturedProducts(products);
      } catch (error) {
        console.error("Error fetching featured products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedProducts();
  }, []);

  const handleAddToCart = async (product) => {
    setLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        const cartRef = collection(firestore, "users", user.uid, "cart");

        // Check if the product is already in the cart
        const querySnapshot = await getDocs(cartRef);
        const existingCartItem = querySnapshot.docs.find(
          (doc) => doc.data().productId === product.id
        );

        if (existingCartItem) {
          // If the product is already in the cart, update the quantity
          const cartItemRef = doc(cartRef, existingCartItem.id);
          await updateDoc(cartItemRef, {
            quantity: existingCartItem.data().quantity + 1,
          });
          alert("Quantity updated in cart successfully!");
        } else {
          await addDoc(cartRef, {
            productId: product.id,
            productName: product.title,
            productPrice: product.price,
            productImage: product.postImage,
            quantity: 1,
            // Add other product details as needed
          });

          alert("Item added to cart successfully!");
        }
      } else {
        console.log("User not logged in. Redirect to login page.");
        // You can implement your navigation logic here
        // Example: navigate('/login');
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <h2>Featured Products</h2>
      {featuredProducts.length > 0 ? (
        <div id="products-grid">
          {featuredProducts.map((product) => (
            <div className="product-card" key={product.id}>
              <Link className="links" to={`/products/${product.id}`}>
                <img className="img" src={product.image} alt="Product" />

                <p> {product.title}</p>
                {product.prices && product.prices.length > 0 ? (
                  <>
                    <p>Price: {product.prices[0].price}</p>
                    <p>Delivery in: {product.duration} minutes</p>
                    <p>
                      <span>Ratings:</span>
                      <span>
                        <RatingComponent productId={product.id} />
                      </span>
                    </p>
                  </>
                ) : (
                  <p>No price information available</p>
                )}
                <button>View Order</button>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No featured products available.</p>
      )}
    </div>
  );
};

export default FeaturedProducts;
