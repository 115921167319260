// LoaderComponent.js
import React from "react";
import "./Loader.css";

const LoaderComponent = ({ isLoading }) => {
  return (
    <div className={`loader-container ${isLoading ? "show-loader" : ""}`}>
      <div className="loader"></div>
    </div>
  );
};

export default LoaderComponent;
