// FeaturedProductsList.js
import React, { useState, useEffect } from "react";
import { collection, getDocs, firestore } from "./utils/Firebase";
import { Link } from "react-router-dom";
import "./product.css";
import AddToFeaturedButton from "./utils/FeaturedButton";
import RatingComponent from "./utils/Rating";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaArrowRight,
  FaCartPlus,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import LoaderComponent from "./utils/Loader";
import Slider from "react-slick";
import { FiLoader } from "react-icons/fi";

const FeaturedProductsList = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [slidesToShow, setSlidesToShow] = useState(6);

  useEffect(() => {
    const fetchFeaturedProducts = async () => {
      try {
        const featuredCollectionRef = collection(firestore, "featuredProducts");
        const snapshot = await getDocs(featuredCollectionRef);

        const products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setFeaturedProducts(products);
      } catch (error) {
        console.error("Error fetching featured products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedProducts();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(2);
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(2);
      }
    };

    // Initial setup
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false, // Hide navigation arrows
    slidesToShow: slidesToShow,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false, // Hide navigation arrows
    slidesToShow: slidesToShow,
  };
  if (loading) {
    return <FiLoader className="loader" />;
  }

  const renderFeaturedProducts = () => {
    return (
      <div style={{ background: "white", padding: "1rem 0" }}>
        <h2 style={{ textAlign: "center" }}>
          Special Sales{" "}
          <Link to="/featured-products" style={{ textDecoration: "none" }}>
            <FaChevronCircleRight />
          </Link>
        </h2>
        {featuredProducts.length > 0 ? (
          <Slider {...settings2}>
            {featuredProducts.slice(0, 8).map((product) => (
              <div className="product-slider" key={product.id}>
                <div className=" slider-card">
                  <Link className="link" to={`/featured-products`}>
                    <img className="img" src={product.image} alt="Product" />
                  </Link>
                  <h3 className="title">{product.title}</h3>
                  <RatingComponent productId={product.id} />
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>No Special Package available.</p>
        )}
      </div>
    );
  };

  if (loading) {
    return <LoaderComponent />;
  }

  return renderFeaturedProducts();
};

export default FeaturedProductsList;
