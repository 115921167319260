import React from "react";
import { getAuth, signOut } from "firebase/auth";
import { FaPowerOff } from "react-icons/fa";

const auth = getAuth(); // Initialize auth

const LogoutButton = ({ onLogout }) => {
  const user = auth.currentUser;
  const handleLogout = async () => {
    try {
      await signOut(auth);
      onLogout();
    } catch (error) {
      console.error("Error signing out:", error.message);
    }
  };

  return (
    user && (
      <button
        style={{ width: "100%", margin: "0 auto", background: "goldenrod" }}
        onClick={handleLogout}
      >
        <FaPowerOff />
      </button>
    )
  );
};

export default LogoutButton;
