// Navbarmenu.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { auth } from "./utils/Firebase";
import "./Navbarmenu.css"; // Import your custom styles
import Menu from "./utils/Menu";
import { FaCartArrowDown, FaUserAlt } from "react-icons/fa";
import CartIcon from "../Cart/CartIcon";
import logo from "../img/logo2.png";

const Navbarmenu = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="navbar-container">
      {/*<FaUserAlt />*/}

      <Link to={`/`} className="logo-link">
        <img
          src={logo} // Replace with the actual path to your logo
          alt="Logo"
          className="logo"
        />
      </Link>

      <div className="user-actions">
        {user ? (
          <Link
            style={{
              textDecoration: "none",
              color: "rgb(245, 201, 9)",
              fontSize: "1.4rem",
            }}
            to={`/profile`}
            className="profile-link"
          >
            <FaUserAlt />
          </Link>
        ) : (
          <Link
            style={{
              textDecoration: "none",
              color: "rgb(245, 201, 9)",
              fontSize: "1.4rem",
            }}
            to="/welcome"
          >
            <FaUserAlt />
          </Link>
        )}
      </div>
    </div>
  );
};

export default Navbarmenu;
