// SingleProductPage.jsx
import React, { useState, useEffect } from "react";
import {
  firestore,
  doc,
  getDoc,
  collection,
  addDoc,
} from "../components/utils/Firebase";
import { FiLoader } from "react-icons/fi";
import { useParams } from "react-router-dom";
import AddToFeaturedButton from "../components/utils/FeaturedButton"; // Import AddToFeaturedButton
import RatingComponent from "./utils/Rating";
import "./singlepage.css";
import { FaMinus, FaMinusCircle, FaPhone, FaPlusCircle } from "react-icons/fa";

const SingleProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSwallowOption, setSelectedSwallowOption] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [isOrderModalOpen, setOrderModalOpen] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({ name: "", address: "" });
  const isAdmin = true; // Set isAdmin to true or false based on your logic

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productDocRef = doc(firestore, "posts", productId);
        const productSnapshot = await getDoc(productDocRef);

        if (productSnapshot.exists()) {
          setProduct({
            id: productSnapshot.id,
            ...productSnapshot.data(),
          });
        } else {
          console.error("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product details:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const handleOrderClick = () => {
    setOrderModalOpen(true);
  };

  const calculateTotalPrice = (option, swallowOption, quantity) => {
    let totalPrice = 0;

    // Calculate the total price based on the selected regular price option
    if (product.prices && product.prices.length > 0 && option) {
      const selectedPriceOption = product.prices.find(
        (priceOption) => priceOption.option === option
      );

      if (selectedPriceOption) {
        totalPrice += parseInt(selectedPriceOption.price, 10);
      }
    }

    // Add the cost of the selected swallow option if applicable (only for soup category)
    if (product.mainCategory === "Soup" && swallowOption) {
      const selectedSwallowOption = product.swallowOptions.find(
        (swallow) => swallow.option === swallowOption
      );

      if (selectedSwallowOption) {
        totalPrice += parseInt(selectedSwallowOption.price, 10);
      }
    }

    // Multiply by quantity
    totalPrice *= quantity;

    setTotalPrice(totalPrice);
  };

  const handleConfirmOrder = () => {
    const orderDetails = {
      productId: product.id,
      productName: product.title,
      selectedOption,
      selectedSwallowOption:
        product.mainCategory === "Soup" ? selectedSwallowOption : null,
      quantity,
      totalPrice,
      customerName: customerInfo.name,
      customerAddress: customerInfo.address,
      oderStatus: "Preparing",
    };

    // Assume you have an "orders" collection in Firestore
    // You should replace "orders" with the actual collection name
    const ordersCollection = collection(firestore, "orders");

    // Add the order details to Firestore
    addDoc(ordersCollection, orderDetails)
      .then(() => {
        // Successfully added order details

        // Construct the message for WhatsApp
        const whatsappMessage = `*New Order:*\n\nFood: ${
          orderDetails.productName
        }\nOption: ${orderDetails.selectedOption}\nSwallow Option: ${
          orderDetails.selectedSwallowOption || "N/A"
        }\nQuantity: ${orderDetails.quantity}\nTotal Price: N${
          orderDetails.totalPrice
        }\n\nCustomer Details:\nName: ${orderDetails.customerName}\nAddress: ${
          orderDetails.customerAddress
        }`;

        // Create a WhatsApp link with the message
        const whatsappLink = `https://wa.me/2348130979300?text=${encodeURIComponent(
          whatsappMessage
        )}`;

        // Open the link in a new tab or window
        window.open(whatsappLink, "_blank");

        // Reset state and close the modal
        setOrderModalOpen(false);
        setSelectedOption("");
        setSelectedSwallowOption("");
        setQuantity(1);
        setCustomerInfo({ name: "", address: "" });
      })
      .catch((error) => {
        console.error(
          "Error adding order details to Firestore:",
          error.message
        );
      });
  };

  if (loading) {
    return <FiLoader className="loader" />;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleCall = () => {
    // You can replace the number with the desired phone number
    window.location.href = "tel:+2348130979300";
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    calculateTotalPrice(event.target.value, selectedSwallowOption, quantity);
  };

  const handleSwallowOptionChange = (event) => {
    setSelectedSwallowOption(event.target.value);
    calculateTotalPrice(selectedOption, event.target.value, quantity);
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + 1));
    calculateTotalPrice(selectedOption, selectedSwallowOption, quantity + 1);
  };

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
    calculateTotalPrice(selectedOption, selectedSwallowOption, quantity - 1);
  };
  return (
    <div className="product-container">
      <div className="product-img-container">
        {/* Pass product and isAdmin as props to AddToFeaturedButton */}
      </div>
      <div className="product-detail-container">
        <img className="product-img" src={product.image} alt="Product" />
        <h2>{product.title}</h2>
        <div className="detail-description-grid">
          <div className="detail-description">
            <p>Description: {product.description}</p>
            <p>
              Rating: <RatingComponent productId={product.id} />
            </p>
            <p>Delivery in: {product.duration} minutes</p>
          </div>
          <div className="detail-option">
            {product.prices && product.prices.length > 0 ? (
              <>
                <div className="option-head">
                  <p>Options:</p>
                  {product.prices.map((priceOption, index) => (
                    <div className="option-div" key={index}>
                      <span>
                        <input
                          className="option"
                          type="radio"
                          id={`option${index}`}
                          name="options"
                          value={priceOption.option}
                          checked={selectedOption === priceOption.option}
                          onChange={handleOptionChange}
                        />
                      </span>
                      <span>
                        <label className="option" htmlFor={`option${index}`}>
                          {priceOption.option}: N{priceOption.price}
                        </label>
                      </span>
                    </div>
                  ))}

                  {selectedOption && (
                    <div>
                      <p>Order {selectedOption}</p>
                      <p></p>
                    </div>
                  )}

                  {product.mainCategory === "Soup" && (
                    <>
                      <p>Select Swallow Option</p>
                      {product.swallowOptions &&
                      product.swallowOptions.length > 0 ? (
                        <div>
                          {product.swallowOptions.map(
                            (swallowOption, index) => (
                              <div className="option-div" key={index}>
                                <input
                                  type="radio"
                                  className="option"
                                  id={`swallowOption${index}`}
                                  name="swallowOptions"
                                  value={swallowOption.option}
                                  checked={
                                    selectedSwallowOption ===
                                    swallowOption.option
                                  }
                                  onChange={handleSwallowOptionChange}
                                />
                                <label
                                  className="option"
                                  htmlFor={`swallowOption${index}`}
                                >
                                  {swallowOption.option}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      ) : null}
                    </>
                  )}

                  <p>
                    Number of plate:{" "}
                    <span onClick={decrementQuantity}>
                      <FaMinusCircle />{" "}
                    </span>{" "}
                    {quantity}{" "}
                    <span onClick={incrementQuantity}>
                      <FaPlusCircle />
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <p>No price information available</p>
            )}
          </div>
        </div>
        {totalPrice > 0 && (
          <p
            style={{
              textAlign: "center",
              background: "goldenrod",
              padding: "1rem",
              borderRadius: "20px",
            }}
          >
            Total Price: N{totalPrice}
          </p>
        )}
        <div className="product-detail-container-button">
          <div className="button-call">
            <span onClick={handleCall}>
              <FaPhone />
            </span>{" "}
          </div>
          <div className="button-order">
            <span onClick={handleOrderClick}>Order</span>{" "}
          </div>
        </div>
        <hr />
        {/* Order Modal */}
        {isOrderModalOpen && (
          <div className="order-modal">
            <h3>Enter your details</h3>

            <input
              placeholder="enter name"
              type="text"
              value={customerInfo.name}
              onChange={(e) =>
                setCustomerInfo({ ...customerInfo, name: e.target.value })
              }
              required
            />

            <input
              type="text"
              value={customerInfo.address}
              onChange={(e) =>
                setCustomerInfo({ ...customerInfo, address: e.target.value })
              }
              required
              placeholder="enter your address"
            />
            <br />
            <br />

            <button onClick={handleConfirmOrder}>Confirm Order</button>
          </div>
        )}
      </div>
      <br />
    </div>
  );
};

export default SingleProductPage;
