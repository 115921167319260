import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  firestore,
} from "../components/utils/Firebase";

import "./AdminOrder.css";
import { FiLoader } from "react-icons/fi";

const AdminOrder = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        console.log("Fetching orders...");
        const ordersRef = collection(firestore, "orders");
        const awaitingShippingOrdersQuery = query(ordersRef);
        const ordersSnapshot = await getDocs(awaitingShippingOrdersQuery);
        console.log("Snapshot:", ordersSnapshot);
        const ordersList = ordersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched orders:", ordersList);
        setOrders(ordersList);
      } catch (error) {
        console.error("Error fetching orders:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleOrderShipped = async (orderId) => {
    try {
      const orderRef = doc(firestore, "orders", orderId);
      await updateDoc(orderRef, {
        orderStatus: "Out for Deliver",
      });
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? { ...order, orderStatus: "out for Delivery" }
            : order
        )
      );
      console.log(`Order ${orderId} marked as shipped successfully!`);
    } catch (error) {
      console.error("Error updating order status:", error.message);
    }
  };

  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div className="admin-order-container">
      <h2>Admin Orders</h2>
      {orders.length > 0 ? (
        <div>
          {orders.map((order) => (
            <div key={order.id} className="admin-order-item">
              <h3>Order ID: {order.id}</h3>
              <p>Name: {order.customerName}</p>
              <p>Deliver to: {order.customerAddress}</p>
              <p>
                Menu: {order.productName} <span>( {order.selectedOption})</span>
                <span> {order.selectedSwallowOption}</span>
              </p>
              <p>
                Total: N{order.totalPrice}{" "}
                <span>( Quantity: {order.quantity})</span>
              </p>

              <p>Order Status: {order.orderStatus}</p>
              <button
                style={{ background: "goldenrod " }}
                onClick={() => handleOrderShipped(order.id)}
                disabled={order.orderStatus === "Ready for Delivery"}
              >
                Out for Delivery
              </button>
              <div className="order-grid"></div>
            </div>
          ))}
        </div>
      ) : (
        <p>No orders awaiting shipping.</p>
      )}
    </div>
  );
};

export default AdminOrder;
