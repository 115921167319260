// UserProfile.jsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, firestore } from "./utils/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

import "./UserProfile.css";
import { FiLoader } from "react-icons/fi";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [logoutMessage, setLogoutMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        setUser(authUser);

        const userDocRef = doc(firestore, "users", authUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          setProfileData(userDocSnapshot.data());
        }

        // Fetch posts created by the user
        const postsQuery = query(
          collection(firestore, "posts"),
          where("userId", "==", authUser.uid)
        );
        const postsSnapshot = await getDocs(postsQuery);
        const userPosts = postsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(userPosts);
        setLoading(false);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const UpdateButton = () => (
    <Link to="/Update-profile" className="login-link">
      <button>Update</button>
    </Link>
  );

  return (
    <div className="user-profile-container">
      {loading ? (
        <FiLoader />
      ) : user ? (
        <>
          {user.username && <h2> {profileData.username}</h2>}
          <p>Email: {user.email}</p>
          <h2>{user.username || user.email}</h2>
          {user.bio && <p>Bio: {user.bio}</p>}
          {user.specialty && <p>Specalty: {user.specialty}</p>}

          <UpdateButton />

          {posts.length > 0 ? (
            <div>
              <h3>My works</h3>
              <ul>
                <div className="user-posts-grid">
                  {posts.map((post) => (
                    <div key={post.id} className="user-post-item">
                      <img src={post.postImage} alt={post.title} />
                    </div>
                  ))}
                </div>
              </ul>
            </div>
          ) : (
            <p>No posts yet.</p>
          )}
        </>
      ) : (
        <div className="login-section">
          <p></p>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
