import React, { useState, useEffect } from "react";
import { firestore, collection, doc, getDoc, setDoc } from "../utils/Firebase";
import "firebase/firestore";

const RatingComponent = ({ productId }) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchRating = async () => {
      const productRef = doc(collection(firestore, "posts"), productId);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const { rating: initialRating } = productSnap.data();
        setRating(initialRating || 0);
      }
    };

    fetchRating();
  }, [productId]);

  const handleRating = async (newRating) => {
    // Update Firebase with the new rating
    const productRef = doc(collection(firestore, "posts"), productId);

    await setDoc(productRef, { rating: newRating }, { merge: true });

    setRating(newRating);
  };

  return (
    <span>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          style={{
            cursor: "pointer",
            color: star <= rating ? "gold" : "gray",
          }}
          onClick={() => handleRating(star)}
        >
          ★
        </span>
      ))}
    </span>
  );
};

export default RatingComponent;
