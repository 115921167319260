import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import { firebase, firestore } from "../components/utils/Firebase";
import "./SearchComponent.css";
import PostImage from "./utils/PostImage";
import { FaPlus } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";

const Discover = ({ isAdmin }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuImage, setMenuImage] = useState("");
  const [newMenuItem, setNewMenuItem] = useState({
    title: "",
    description: "",
    availableDate: "",
  });

  useEffect(() => {
    // Fetch menu items from Firestore
    const fetchMenuItems = async () => {
      const menuCollection = collection(firestore, "menu");
      const querySnapshot = await getDocs(
        menuCollection,
        orderBy("timestamp", "desc")
      );

      const items = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      setMenuItems(items);
      setLoading(false);
    };

    fetchMenuItems();
  }, []);

  const handleInputChange = (e) => {
    setNewMenuItem({
      ...newMenuItem,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddMenuItem = async () => {
    try {
      const menuCollection = collection(firestore, "menu");

      // Add new menu item to Firestore
      const newDocRef = await addDoc(menuCollection, {
        ...newMenuItem,
        image: menuImage,
        timestamp: serverTimestamp(),
      });

      setMenuItems((prevMenuItems) => [
        {
          id: newDocRef.id,
          ...newMenuItem,
          image: menuImage,
          timestamp: new Date(),
        },
        ...prevMenuItems,
      ]);

      // Clear the form and close it
      setNewMenuItem({
        title: "",
        description: "",
        image: "",
        availableDate: "",
      });
      setIsOpen(false);
    } catch (error) {
      console.error("Error adding menu item:", error);
    }
  };

  const handleImageUpload = (imageUrl) => {
    setMenuImage(imageUrl);
  };

  const AddMenu = () => {
    setIsOpen(true);
  };

  if (isAdmin && isOpen === true) {
    return (
      <div className="add-menu-form">
        <h3>Add New Menu</h3>
        <label>
          Title:
          <input
            type="text"
            name="title"
            value={newMenuItem.title}
            onChange={handleInputChange}
            placeholder="Enter a creative name"
          />
        </label>
        <label>
          Description:
          <textarea
            name="description"
            value={newMenuItem.description}
            onChange={handleInputChange}
            placeholder="explian the content of the meal"
          />
        </label>
        <PostImage onImageUpload={handleImageUpload} required />
        <label>
          Available Date:
          <input
            type="date"
            name="availableDate"
            value={newMenuItem.availableDate}
            onChange={handleInputChange}
            placeholder="enter a date it will be avaliable for order"
          />
        </label>

        {isAdmin && <button onClick={handleAddMenuItem}>Add Menu</button>}
      </div>
    );
  }

  if (loading) {
    return <FiLoader className="loader" />;
  }

  return (
    <div className="form-div">
      <h2 style={{ textAlign: "center" }}>Discover Our Latest Menu</h2>

      {menuItems.map((menuItem) => (
        <div key={menuItem.id} className="menu-item">
          <h3 style={{ textAlign: "center" }}>{menuItem.title}</h3>
          <p>{menuItem.description}</p>
          <img src={menuItem.image} alt={menuItem.title} />
          <p>Available Date: {menuItem.availableDate}</p>
        </div>
      ))}
      {isAdmin && (
        <button onClick={AddMenu} className="arrow">
          <FaPlus />
        </button>
      )}
    </div>
  );
};

export default Discover;
