import React from "react";
import { Navigate, Route } from "react-router-dom";

const AdminRoute = ({ element, user }) => {
  // Replace 'admin@example.com' with the actual admin email
  const adminEmail = "harryjeffersonswagking@gmail.com";
  const isAdmin = user && user.email === adminEmail;

  return isAdmin ? element : <Navigate to="/home" />;
};

export default AdminRoute;
