// Footer.js

import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import LogoutButton from "./utils/LogoutButton";
import {
  FaFacebook,
  FaFacebookSquare,
  FaInstagram,
  FaMailBulk,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";

const Footer = () => {
  // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual API key
  const googleMapsApiKey = "YOUR_GOOGLE_MAPS_API_KEY";
  const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=Your+Store+Location`;

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-section">
          <h3>Our Menu</h3>
          <ul>
            <li>
              <Link to="/products/Rice">Rice</Link>
            </li>
            <li>
              <Link to="/products/Beans">Beans</Link>
            </li>
            <li>
              <Link to="/products/Soups">Soup</Link>
            </li>
            <li>
              <Link to="/products/Cakes">Cakes</Link>
            </li>
            <li>
              <Link to="/products/Drinks">Drinks</Link>
            </li>
            <li>
              <Link to="/products/Snacks">Snacks</Link>
            </li>
            {/* Add more category links as needed */}
          </ul>
        </div>

        <div className="footer-section">
          <h3>Contact</h3>
          <p>
            <FaMailBulk />
            <a href="mailto:info@chidelfoods.online">info@chidelfoods.online</a>
          </p>
          <p>
            <FaPhone />
            <a href="tel:+234906542851">+2348130979300</a>
          </p>
          <p>
            <FaWhatsapp />
            <a
              href="https://wa.me/2348130979300"
              target="_blank"
              rel="noopener noreferrer"
            >
              +2348130979300
            </a>
          </p>

          <p>
            <FaInstagram />
            <a
              href="https://www.instagram.com/chidelfoods"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </p>
        </div>

        <div className="footer-section">
          <h3>Location</h3>
          <p>IJapo Estate, Akure city. Ondo State</p>
        </div>
      </div>
      <LogoutButton />
      <div className="footer-bottom">
        <p>&copy; 2024 Chidel Food & Pastries . All rights reserved.</p>
      </div>
      <br />
    </footer>
  );
};

export default Footer;
