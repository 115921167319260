import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import "./App.css";
import { auth, authStateChanged } from "./components/utils/Firebase";
import { initializeDatabase } from "./components/utils/Firebase";
import SignUpComponent from "./components/auth/SignUp";
import SignInComponent from "./components/auth/SignIn";
import ForgotPasswordComponent from "./components/auth/ResetPassword";
import UserProfile from "./components/UserProfile";
import Footer from "./components/Footer";
import Welcome from "./components/auth/Welcome";
import BottomNavBar from "./components/Navbar";
import CreatePost from "./components/CreatePost";
import Navbarmenu from "./components/Navbarmenu";
import UpdateProfile from "./components/UpdateProfile";

import AdminRoute from "./AdminInterface";
import Notification from "./components/Notification";
import ProductHomePage from "./components/Feeds";
import SingleProductPage from "./components/SingleProductPage";
import LandingPage from "./components/Landingpage";
import FeaturedProducts from "./components/FeaturedComponent";
import Order from "./Order/Order";
import AdminOrder from "./Cart/AdminOrder";
import ScrollToTop from "./components/ScrollToTop";
import CateringBookingForm from "./Order/Booking";
import Discover from "./components/Search";

function App() {
  const [user, setUser] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false); // New state for admin check

  useEffect(() => {
    // Initialize the database structure
    initializeDatabase();

    // Check online status
    const handleOnline = () => setOnlineStatus(true);
    const handleOffline = () => setOnlineStatus(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Initialize the database structure
    initializeDatabase();
  }, []);

  useEffect(() => {
    const unsubscribe = authStateChanged((user) => {
      setUser(user);
      setIsAuthenticated(!!user); // Set isAuthenticated to true if user exists, false otherwise

      setIsAdmin(user && user.email === "harryjeffersonswagking@gmail.com");
    });

    return () => unsubscribe();
  }, []);

  return (
    <HashRouter>
      <ScrollToTop />
      <div>
        <Navbarmenu />
        <div>
          {!onlineStatus && (
            <div>Offline. Please check your internet connection.</div>
          )}
          <Routes>
            <Route path="/" exact element={<LandingPage />} />

            <Route path="/home" element={<LandingPage />} />
            <Route path="/discover" element={<Discover isAdmin={isAdmin} />} />
            <Route path="/booking" element={<CateringBookingForm />} />
            <Route path="/product/:productId" element={<SingleProductPage />} />
            <Route
              path="/products/:category"
              element={<ProductHomePage isAdmin={isAdmin} />}
            />
            <Route
              path="/products/:category/:productId"
              element={<SingleProductPage isAdmin={isAdmin} />}
            />
            <Route
              path="/featured-products"
              element={<FeaturedProducts isAdmin={isAdmin} />}
            />

            <Route
              path="/notification"
              element={<AdminRoute element={<Notification />} user={user} />}
            />
            <Route
              path="/order"
              element={isAdmin ? <AdminOrder /> : <Order />}
            />

            {!user && (
              <>
                <Route path="/Sign-up" element={<SignUpComponent />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/sign-in" element={<SignInComponent />} />
                <Route
                  path="/Forget-password"
                  element={<ForgotPasswordComponent />}
                />
              </>
            )}
            {user && (
              <>
                <Route path="/sell" element={<CreatePost />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/Update-profile" element={<UpdateProfile />} />
              </>
            )}
            <Route path="*" element={<Navigate to="/" LandingPage />} />
          </Routes>
          <br />
          <br />
          <Footer />
          <br />

          <BottomNavBar isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
        </div>
      </div>
    </HashRouter>
  );
}

export default App;
