import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  deleteDoc,
  getDoc,
  doc,
  firestore,
  getDocs,
  setDoc,
  auth,
} from "./Firebase";

const AddToFeaturedButton = ({ product, isAdmin }) => {
  const [isFeatured, setIsFeatured] = useState(false);

  useEffect(() => {
    const checkIfFeatured = async () => {
      try {
        const featuredDocRef = doc(firestore, "featuredProducts", product.id);
        const featuredDoc = await getDoc(featuredDocRef);

        setIsFeatured(featuredDoc.exists());
      } catch (error) {
        console.error("Error checking if featured:", error.message);
      }
    };

    // Make sure isFeatured is initially set correctly
    setIsFeatured(false);
    checkIfFeatured();
  }, [product.id]);

  const createFeaturedCollection = async () => {
    try {
      const featuredCollectionRef = collection(firestore, "featuredProducts");
      await addDoc(featuredCollectionRef, {}); // Add a dummy document to create the collection
    } catch (error) {
      console.error(
        "Error creating featuredProducts collection:",
        error.message
      );
    }
  };

  const handleToggleFeatured = async () => {
    try {
      console.log("Toggle Featured Button Clicked");

      if (isAdmin) {
        const featuredDocRef = doc(firestore, "featuredProducts", product.id);
        if (isFeatured) {
          console.log("Removing from Featured Products");
          await deleteDoc(featuredDocRef);
        } else {
          const featuredCollectionRef = collection(
            firestore,
            "featuredProducts"
          );
          const collectionExists = await getDocs(featuredCollectionRef).then(
            (snapshot) => !snapshot.empty
          );

          if (!collectionExists) {
            await createFeaturedCollection();
          }

          console.log("Adding to Featured Products");
          await setDoc(featuredDocRef, product);
        }

        setIsFeatured((prev) => !prev);
      } else {
        console.log("Only admins can toggle featured status.");
      }
    } catch (error) {
      console.error("Error toggling featured status:", error.message);
    }
  };

  return (
    <button
      onClick={handleToggleFeatured}
      style={{
        backgroundColor: isFeatured ? "#080808" : "red",
        color: "#fff",
        padding: "8px",
        cursor: "pointer",
      }}
    >
      {isFeatured ? "Remove from Featured" : "Add to Featured"}
    </button>
  );
};

export default AddToFeaturedButton;
