// LandingPage.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import BannerSlider from "./utils/BannerSlider";
import Rice from "../img/Rice.jpg";
import SoupandSwallows from "../img/soup2.png";
import Cakes from "../img/cake.png";
import Beans from "../img/Beans.jpg";
import Porridge from "../img/porrid.jpg";
import Snacks from "../img/snacks.jpg";
import SoupBowls from "../img/soupbowls.png";
import Drinks from "../img/drink.jpg";
import FeaturedProductsList from "./FeaturedProductList";
import {
  FaAward,
  FaHamburger,
  FaPhoneSquareAlt,
  FaPhoneVolume,
  FaShippingFast,
} from "react-icons/fa";
import ServiceContainer from "./Services";

const categories = [
  "Rice",
  "Soup",
  "Soup Bowls",
  "Beans",
  "Porridge",
  "Snacks",
  "Cakes",
  "Drinks",
];

const categoryImages = {
  Rice: Rice,
  Soup: SoupandSwallows,
  "Soup Bowls": SoupBowls,
  Beans: Beans,
  Porridge: Porridge,
  Snacks: Snacks,
  Cakes: Cakes,
  Drinks: Drinks,
};

const LandingPage = () => {
  return (
    <div className="main-container landing-page">
      <BannerSlider />
      <div className="customer">
        <p class="m-0">
          <FaPhoneSquareAlt /> Call To Order:{" "}
          <span class="m-0">+2348130979300</span>
        </p>
      </div>{" "}
      <br />
      <div>
        <div className="service-grid">
          <div className="service-div">
            <div>
              <h1>
                <FaAward />
              </h1>
            </div>
          </div>
          <div className="service-div">
            <div>
              <h1>
                <FaShippingFast />
              </h1>
            </div>
          </div>
          <div className="service-div">
            <div>
              <h1>
                <FaPhoneVolume />
              </h1>
            </div>
          </div>
        </div>
      </div>
      <FeaturedProductsList />
      <div className="categories-container">
        <h2 style={{ color: "rgb(255, 142, 12)", textAlign: "center" }}>
          Food Menu <FaHamburger />{" "}
        </h2>
        <div className="categories-grid">
          {categories.map((category) => (
            <Link
              to={`/products/${category}`}
              className="category-card"
              key={category}
              style={{
                backgroundImage: `url(${categoryImages[category]})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "#0001017e",
              }}
            >
              <span className="category-title">{category}</span>
            </Link>
          ))}
        </div>
      </div>
      <ServiceContainer />
    </div>
  );
};

export default LandingPage;
