// PostImage.jsx
import React, { useState } from "react";
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getFirestore, arrayUnion, serverTimestamp } from "firebase/firestore";
import firebaseApp from "./Firebase";

const PostImage = ({ onImageUpload }) => {
  const firebase = firebaseApp;
  const [image, setImage] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Upload the image to Firebase Storage
      const storage = getStorage(firebase);
      const storageReference = storageRef(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageReference, file);

      try {
        await uploadTask;

        // Get the download URL after the upload is complete
        const imageUrl = await getDownloadURL(storageReference);
        console.log("img Url", imageUrl);

        // Pass the image URL back to the parent component
        onImageUpload(imageUrl);

        setImage(imageUrl);
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      {image && <img src={image} alt="Post" style={{ maxWidth: "100%" }} />}
    </div>
  );
};

export default PostImage;
